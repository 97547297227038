import HeaderSection from './components/headerSection'
import FooterSection from './components/footerSection'

import FaqsSection from './components/faqsSection'

function App() {
  return (
    <div>
      <HeaderSection/>

      <FaqsSection/>

      <footer>
      <FooterSection/>
      </footer>
         


    </div>
  );
}

export default App;
