export default function Example() {
    return (
      <div className="bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Valagan Inc.</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            We are an american technology company.
          </p>
        </div>
      </div>
    )
  }
  